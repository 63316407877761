import * as S from "./styles";

const Grid = ({container, item, ...props}) => {

    const renderGrid = () => {
        if (container) return <S.ContainerRow {...props} />;

        if (item) return <S.ItemColumn {...props} />;

        return <></>;
    };

    return renderGrid();
};

export default Grid;
