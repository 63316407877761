import styled from "styled-components";

export const Wrapper = styled.div``;

export const ContainerRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'center' };
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'center' };
    box-sizing: border-box;
    width: 100%;
`;

export const ItemColumn = styled.div`
    margin: 0;
    box-sizing: border-box;
    flex-grow: 0;

    @media only screen and (min-width: 0px) {
        ${({ xs }) => xs && getItemSize(xs) }
    }

    @media only screen and (min-width: 600px) {
        ${({ sm }) => sm && getItemSize(sm) }
    }

    @media only screen and (min-width: 900px) {
        ${({ md }) => md && getItemSize(md) }
    }

    @media only screen and (min-width: 1200px) {
        ${({ lg }) => lg && getItemSize(lg) }
    }

    @media only screen and (min-width: 1536px) {
        ${({ xl }) => xl && getItemSize(xl) }
    }
`;

const getItemSize = value => {
    if (!value) return;

    let percentage = value / 12 * 100;

    return `
        max-width: ${percentage}%;
        flex-basis: ${percentage}%;
    `;
};
