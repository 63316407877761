import Head from "next/head";

const Seo = ({ title, description }) => {
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description || "Sinta-se linda e transforme seu corpo fazendo exercício em casa. Nós vamos te ajudar a treinar em casa para alcançar resultados incríveis. Teste por 7 dias"} />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description || "Sinta-se linda e transforme seu corpo fazendo exercício em casa. Nós vamos te ajudar a treinar em casa para alcançar resultados incríveis. Teste por 7 dias"} />
        </Head>
    );
};

export default Seo;
