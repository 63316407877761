import styled from 'styled-components'

export const TimeContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CounterPill = styled.div`
    font-family: sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: #fff;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 2rem;
        line-height: 2.2rem;
    }
`;
