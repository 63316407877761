import styled from "styled-components";

export const Wrapper = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 66px;
    position: relative;
    padding: 0px 30px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    z-index: 1000;

    @media ${(props) => props.theme.device.laptop} {
        justify-content: space-between;
    }

    .brand-image {
        max-width: 100%;
        padding: 10px;
    }

    .icon-image {
        max-width: 75%;
        margin-left: 10px;
    }
`;

export const LogoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 10%;
    min-width: 120px;
    min-height: 60px;

    @media ${(props) => props.theme.device.laptop} {
        width: 9%;
    }
`;

export const LogoLink = styled.a`
    width: 100%;
`;

export const SecureStrip = styled.div`
    display: flex;
    align-items: center;
`;

export const TextContainer = styled.div`
    font-family: sans-serif;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #9B9B9B;
`;

export const IconContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    margin-left: 15px;
`;
