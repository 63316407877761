import React from "react";
import Hidden from 'Components/Atoms/Hidden';
import TimerIcon from "Assets/Image/timer.png";
import UnlockIcon from "Assets/Image/unlock-white.png";
import Image from 'next/image';
import * as S from "./styled";

export default function SpecialHeader(props) {

    return (
        <S.Wrapper data-testid={"header-basic"}>
            <S.TimerContainer>
                <S.TimerGroup>
                    <S.CustomTimerCounter />
                    <S.TimerIcon>
                        <Image
                            src={TimerIcon}
                            layout="fill"
                            objectFit="contain"
                            className="icon-image" alt="Timer Icon" />
                    </S.TimerIcon>
                    <S.TimerText>
                        <Hidden mdDown>
                            Preencha o formulário abaixo dentro do prazo para garantir esta oferta
                        </Hidden>
                        <Hidden mdUp>
                            Preencha dentro do prazo para garantir a oferta
                        </Hidden>
                    </S.TimerText>
                </S.TimerGroup>
            </S.TimerContainer>
            <Hidden mdDown>
                <S.SecureStrip>
                    <S.TextContainer>
                        Ambiente 100% seguro
                    </S.TextContainer>
                    <S.IconContainer>
                        <Image
                            src={UnlockIcon}
                            layout="fill"
                            objectFit="cover"
                            className="icon-image" alt="Unlock Icon" />
                    </S.IconContainer>
                </S.SecureStrip>
            </Hidden>
        </S.Wrapper>
    );
}
