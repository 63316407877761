import SpecialHeader from "Components/Molecules/SpecialHeader";
import Header from "Components/Molecules/Header";
import * as S from "./styled";

export default function LayoutPage({
    showHeader = true,
    customTimer = true,
    testID,
    children,
    ...props
}) {
    return (
        <>
            {showHeader && ( customTimer ? SpecialHeader() : Header() )}
            <S.Main data-testid={testID} role="main">
                {children}
            </S.Main>
        </>
    );
}
