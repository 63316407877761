import React, { useEffect, useState, useRef} from "react";
import * as S from "./styles";

function TimeCounter({timeLeftTo = 900}) {
    const seconds = useRef(timeLeftTo);
    const [timeLeft, setTimeLeft] = useState({h: '00', m: '00', s: '00'});

    useEffect(() => {
        setTimeLeft(secondsToTime(seconds.current));

        const intervalId = setInterval(() => {
            seconds.current = seconds.current - 1;

            setTimeLeft(secondsToTime(seconds.current));

            if (seconds.current === 0) {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const secondsToTime = secs => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return {
            h: String(hours).padStart(2, '0'),
            m: String(minutes).padStart(2, '0'),
            s: String(seconds).padStart(2, '0')
        };
    };

    const renderTimeLeft = () => {
        return (
            <S.CounterPill>
                {`${timeLeft.h}:${timeLeft.m}:${timeLeft.s}`}
            </S.CounterPill>
        );
    };

    return <S.TimeContainer>{renderTimeLeft()}</S.TimeContainer>;
}

export default TimeCounter;

