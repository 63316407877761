import React from "react";
import Hidden from 'Components/Atoms/Hidden';
import LogoImage from "Assets/Image/logo.png";
import UnlockIcon from "Assets/Image/unlock.png";
import Image from 'next/image';
import * as S from "./styled";

export default function Header(props) {

    return (
        <S.Wrapper data-testid={"header-basic"}>
            <S.LogoContainer>
                <S.LogoLink href={"/"}>
                    <Image
                        src={LogoImage}
                        layout="responsive"
                        width={199}
                        height={81}
                        priority={true}
                        className="brand-image" alt="Brand Image" />
                </S.LogoLink>
            </S.LogoContainer>
            <Hidden smDown>
                <S.SecureStrip>
                    <S.TextContainer>
                        Ambiente 100% seguro - SSL criptografado
                    </S.TextContainer>
                    <S.IconContainer>
                        <Image
                            src={UnlockIcon}
                            layout="fill"
                            objectFit="cover"
                            className="icon-image" alt="Unlock Icon" />
                    </S.IconContainer>
                </S.SecureStrip>
            </Hidden>
        </S.Wrapper>
    );
}
