import styled from "styled-components";
import TimeCounter from "Components/Atoms/TimeCounter";

export const Wrapper = styled.header`
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 80px;
    padding: 0px 30px;
    background-color: #00CC90;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    z-index: 1000;
    top: 0;

    .brand-image {
        max-width: 100%;
        padding: 10px;
    }

    .icon-image {
        max-width: 75%;
        margin-left: 10px;
    }

    @media ${(props) => props.theme.device.tablet} {
        min-height: 66px;
    }
`;

export const TimerContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 120px;
    min-height: 60px;
`;

export const TimerGroup = styled.div`
    position: relative;
    display: flex;

`;

export const CustomTimerCounter = styled(TimeCounter)`
    position: relative;
    display: flex;
`;

export const TimerIcon = styled.div`
    position: relative;
    display: none;
    align-items: center;
    min-width: 38px;
    margin: 0px 15px;

    @media ${(props) => props.theme.device.tablet} {
        display: flex;
    }
`;

export const TimerText = styled.div`
    font-family: sans-serif;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #fff;
    max-width: 200px;
    margin-left: 15px;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 0.9rem;
        line-height: 1.2rem;
        max-width: 255px;
        margin-left: 0px;
    }
`;

export const SecureStrip = styled.div`
    display: flex;
    align-items: center;
`;

export const TextContainer = styled.div`
    font-family: sans-serif;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #fff;
`;

export const IconContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    margin-left: 15px;
`;
