/* eslint-disable react-hooks/exhaustive-deps */
import useWindowDimensions from "Utils/hooks/useWindowDimensions";

const Hidden = ({
        smUp = false,
        smDown = false,
        mdUp = false,
        mdDown = false,
        lgUp = false,
        lgDown = false,
        xlUp = false,
        xlDown = false,
        children,
        ...props
    }) => {

    const { width: screenWidth } = useWindowDimensions();
    const sizes = {
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
    };

    let visible = false;

    if (screenWidth !== undefined) {
        if (smUp && screenWidth <= sizes.sm ) visible = true;
        if (smDown && screenWidth > sizes.sm) visible = true;

        if (mdUp && screenWidth <= sizes.md ) visible = true;
        if (mdDown && screenWidth > sizes.md) visible = true;

        if (lgUp && screenWidth <= sizes.lg ) visible = true;
        if (lgDown && screenWidth > sizes.lg) visible = true;

        if (xlUp && screenWidth <= sizes.xl ) visible = true;
        if (xlDown && screenWidth > sizes.xl) visible = true;
    }

    if (!visible) return null;

    return children
};

export default Hidden;
